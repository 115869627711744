var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"importTemplate-container"},[_c('form-panel',{ref:"formPanel",staticClass:"form-container",attrs:{"hasHeader":_vm.hasHeader},scopedSlots:_vm._u([{key:"saveBeforeSlot",fn:function(){return [(_vm.showCloseBtn)?_c('v-button',{attrs:{"text":"关闭"},on:{"click":_vm.closeForm}}):_vm._e()]},proxy:true}])},[_c('el-form-item',{attrs:{"label":_vm.label,"rules":[
        {
          required: false,
          message: '',
          trigger: ['change', 'blur'],
        },
      ],"prop":"modelIconPic"}},[_c('v-button',{attrs:{"text":"下载"},on:{"click":_vm.downloadTemplate}})],1),_c('el-form-item',{attrs:{"label":"上传模板","rules":[
        {
          required: false,
          message: '请上传应用图标',
          trigger: ['change', 'blur'],
        },
      ],"prop":"modelIconPic"}},[_c('v-fileUpload',{attrs:{"otherParams":_vm.otherParams,"limit":1,"imgUrls":_vm.form.modelIconPic,"uploadImgUrl":_vm.uploadImgUrl},on:{"update:imgUrls":function($event){return _vm.$set(_vm.form, "modelIconPic", $event)},"update:img-urls":function($event){return _vm.$set(_vm.form, "modelIconPic", $event)}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }